/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import theme from "../gatsby-plugin-theme-ui"
import { Layout } from "../components/Layout"
import { MediaMatcher } from "../components/MediaMatcher"
import {
  GatsbyImagePropType,
  PrismicPlainTextFieldPropType,
  PrismicRichTextFieldPropType,
  SeoFieldsPropType,
  JournazinePropTypes,
} from "../utils/propTypes"
import { extractBaseSeoFields } from "../utils/seoUtils"
import { transformJournazinesData } from "../utils/journazineUtils"
import { RichText } from "../components/RichText"
import { Masthead } from "../components/Masthead"
import { Divider } from "../components/Divider"
import { DividerType } from "../hooks/divider"
import { JournazineBriefCard } from "../components/JournazineBriefCard"
import { Heading } from "theme-ui"
import { ItemsGrid } from "../components/ItemsGrid"

export const JournazinesPageTemplate = ({ data }) => {
  const { title, description, masthead_background } =
    data.prismicJournazinesPage.data
  const journazines = transformJournazinesData(data.allPrismicJournazine)

  const seoProps = extractBaseSeoFields({
    ...data.prismicJournazinesPage.data,
    type: data.prismicJournazinesPage.type,
    uid: data.prismicJournazinesPage.uid,
  })

  const isMobile = MediaMatcher.useMedia({
    mobilePortrait: true,
    tablet: false,
  })

  return (
    <Layout seoProps={seoProps}>
      <Masthead
        heading={title.text}
        headingStyles={{
          color: "sandYellow",
          mt: ["50px", "50px", 0],
        }}
        showDivider={isMobile}
        dividerType={DividerType.DEFAULT_YELLOW}
        image={masthead_background}
        containerStyles={{
          height: ["200px", "200px", "300px", "300px"],
        }}
      />
      <div
        sx={{
          ...theme.basePageStyles.contentContainer,
          mb: "80px",
        }}
      >
        <RichText sxOverrides={{}} text={description} />
        <Divider
          type={DividerType.SEEDLING}
          styles={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: ["80px", "100px", "100px", "150px"],
            mt: ["20px", "20px", "30px", "50px"],
            mb: [null, null, null, "80px"],
          }}
        />
        <Heading
          as="h1"
          variant="headingOlive"
          sx={{
            textAlign: ["left", "center", "center"],
            mb: 6,
          }}
        >
          Issues
        </Heading>
        <ItemsGrid
          items={journazines}
          component={JournazineBriefCard}
          emphasizeFirst={!isMobile}
          gap={4}
        />
      </div>
    </Layout>
  )
}
JournazinesPageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicJournazinesPage: PropTypes.shape({
      type: PropTypes.string,
      uid: PropTypes.string,
      data: PropTypes.shape({
        ...SeoFieldsPropType,
        title: PrismicPlainTextFieldPropType,
        description: PrismicRichTextFieldPropType,
        masthead_background: PropTypes.shape({
          gatsbyImageData: GatsbyImagePropType,
        }),
      }),
    }),
    allPrismicJournazine: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(JournazinePropTypes),
        })
      ),
    }),
  }),
}
