import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { JournazinesPageTemplate } from "./JournazinesPageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query JournazinesPageTemplate {
    prismicJournazinesPage {
      _previewable
      type
      uid
      data {
        masthead_background {
          gatsbyImageData(width: 1920, placeholder: BLURRED)
          thumbnails {
            mobile_portrait {
              gatsbyImageData(width: 420, placeholder: BLURRED)
            }
            mobile_landscape {
              gatsbyImageData(width: 900, placeholder: BLURRED)
            }
          }
        }
        title {
          text
        }
        description {
          richText
        }
        meta_title
        meta_description
        language
        canonical_url {
          url
        }
        opengraph_image {
          url
        }
      }
    }

    allPrismicJournazine(
      sort: { fields: [data___publication_date], order: [DESC] }
    ) {
      edges {
        node {
          ...JournazineCard
        }
      }
    }
  }
`
export default withPrismicPreview(JournazinesPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
